<template>
  <div id="create-edit-course">
    <div class="div-companies bg-white content-wrapper">
      <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center">
              <h4 style="color: #0007" class="mt-3">
                <i class="fas fa-graduation-cap" aria-hidden="true"></i>
                Academy - Editar Curso
              </h4>

              <div>
                <button @click="updateCourse()" class="btn btn-sm btn-primary mr-2">
                  <span v-if="spinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span v-else>Salvar</span>
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click.prevent="$router.go(-1)">
                  <i class="fa fa-arrow-left mx-1"></i>Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container-fluid">
        <div class="row d-flex">
          <div class="col-12 order-2 col-sm-12 order-lg-1 col-lg-5">

            <div class="card card-custom">
              <div class="d-flex flex-column justify-content-between m-1 p-2">
                <!-- nome -->
                <div class="d-flex flex-column col-12 mb-3">
                  <label>
                    Nome do Curso
                    <span v-if="course.title.length<4 && titleOk" class="font-weight-normal ml-1 text-danger"
                          style="font-size: .7rem">(o nome deve ter entre 4 e 60 caracteres)</span>
                  </label>
                  <input v-model="course.title" type="text" name="name" class="form-control"
                         placeholder="Digite o nome do curso" autocomplete="off"/>
                  <span v-if="course.title.length>50" class="text-small mt-1 w-50"
                        :class="course.title.length>60 ? 'text-danger':'text-dark'" role="alert"
                        style="font-size: .7rem">Tamanho do titulo: {{ course.title.length }}/60</span>
                </div>
                <!-- descricao -->
                <div class="d-flex flex-column col-12 mb-1">
                  <label class="p-0 m-0 mb-2">
                    Descrição
                    <span v-if="course.description.length<10 && descriptionOk"
                          class="font-weight-normal ml-1 text-danger" style="font-size: .7rem">(a descrição deve ter entre 10 e 250 caracteres)</span>
                  </label>
                  <textarea v-model="course.description" type="text" name="description" class="form-control"
                            placeholder="Digite uma descrição. "></textarea>
                  <span v-if="course.description.length>230" class="text-small mt-1 w-50"
                        :class="course.description.length>250 ? 'text-danger':'text-dark'" role="alert"
                        style="font-size: .7rem">Tamanho da descrição: {{ course.description.length }}/250</span>
                </div>
                <!-- imagem -->
                <div class="d-flex flex-column col-12 mt-3">
                  <label class="p-0 m-0">Banner</label>
                  <span class="text-small text-muted m-0 mb-2 p-0" style="font-size: .7rem">Faça upload da capa do curso (1280x720)</span>
                  <input @change="fileInputed()" type="file" name="banner" id="banner" class="btn p-0 m-0"
                         style="font-size: .8rem" accept="image/png, image/jpeg, image/jpg"/>
                  <span v-if="sizeOutRange" class="text-small alert alert-warning p-1 mt-1 w-50" role="alert"
                        style="font-size: .7rem">Tamanho máximo permitido: 3MB</span>
                  <span v-if="typeOutRange" class="text-small alert alert-warning p-1 mt-1 w-50" role="alert"
                        style="font-size: .7rem">Tipos permitidos: jpeg, jpg e png.</span>
                </div>
                <!-- planos -->
                <div class="d-flex flex-column col-12 mt-5">
                  <label class="p-0 m-0">
                    Disponivel para o(s) plano(s):
                    <span v-if="course.plans.length===0" class="font-weight-normal ml-1 text-danger"
                          style="font-size: .7rem">(selecione ao menos um plano.)</span>
                  </label>
                  <div class="d-flex flex-row custom-label">
                    <div class="mr-2">
                      <input type="checkbox" id="basic" name="basic" value="basic" v-model="course.plans">
                      <label class="font-weight-normal ml-1" for="basic">Basic</label>
                    </div>
                    <div class="mr-2">
                      <input type="checkbox" id="essential" name="essential" value="essential" v-model="course.plans">
                      <label class="font-weight-normal ml-1" for="essential">Essencial</label>
                    </div>
                    <div class="mr-2">
                      <input type="checkbox" id="advanced" name="advanced" value="advanced" v-model="course.plans">
                      <label class="font-weight-normal ml-1" for="advanced">Avançado</label>
                    </div>
                    <div class="mr-2">
                      <input type="checkbox" id="professional" name="professional" value="professional"
                             v-model="course.plans">
                      <label class="font-weight-normal ml-1" for="professional">Profissional</label>
                    </div>
                  </div>
                </div>
                <!-- tags -->
                <div class="d-flex flex-column col-12 mt-3">
                  <label for="tag">
                    Digite as tags relacionadas
                    <span v-if="course.tags.length===0 && tagsOk" class="font-weight-normal ml-1 text-danger"
                          style="font-size: .7rem">(cadastre ao menos uma tag.)</span>
                  </label>
                  <input
                      v-model="tag"
                      @keyup="($event.keyCode===13) ? insertTag():null"
                      type="text" id="tag" class="form-control"
                      title="Separe as tags por vírgula (ou não) e press enter."
                      placeholder="Separe as tags por vírgula (ou não) e press enter." autocomplete="off"/>
                  <div class="d-flex flex-row flex-wrap mt-2 mb-2">
                    <div v-for="(tag, index) in course.tags" :key="index" class="mr-1">
                      <Tags :tag="tag" @deleteTag="deleteTag($event)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 order-1 col-sm-12 order-lg-2 col-lg-7 col-xl-5">
            <div class="card card-custom">
              <div class="d-flex justify-content-between align-items-center m-1 p-2">
                <div class="d-flex flex-column">
                  <img
                      :src="course.img ? `${image_url}${course.img}` : require('../../../../assets/images/default-cover.png')"
                      id="preview-banner" alt="Banner" class="img-fluid custom-image">
                  <h2 class="mt-3">{{ course.title ? course.title : "Titulo do curso" }}</h2>
                  <p>{{ course.description ? course.description : "Descrição do curso" }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Tags from './Tags.vue';

export default {
  name: "create_edit_course",

  components: {
    Tags,
  },

  data() {
    return {
      // auxiliar no preenchimento de tags
      tag: '',

      // arquivo a ser enviado
      file: '',

      image_url: process.env.VUE_APP_URL_IMAGE,

      // manipulação spinner
      spinner: false,

      // cursos
      course: {
        title: "Titulo do curso",
        description: "Descrição do curso",
        createdBy: null,
        plans: [],
        tags: [],
        img: null,
        active: false,
        categories: [],
        comments: [],
        createdAt: null,
        updatedBy: null,
        lastModify: null,
        progress: 0
      },
      image: null,
      previewDefaultSrc: null,

      // validacoes (imagem)
      sizeOutRange: false,
      typeOutRange: false,

      // validacoes (objeto curso)
      titleOk: false,
      descriptionOk: false,
      tagsOk: false
    }
  },

  methods: {
    /**
     * Metodo que processa upload de imagem
     */
    fileInputed: async function () {
      var inputBanner = document.querySelector('input[type=file]')
      var preview = document.querySelector('#preview-banner')
      var file = document.querySelector('input[type=file]').files[0]

      // se nao houver arquivo selecionado, preview fica no valor padrao
      if (inputBanner.src === '') {
        preview.src = this.previewDefaultSrc
      }

      // valida o tamanho da imagem
      if (file.size > 3 * 1024 * 1024) {
        inputBanner.value = ''
        preview.src = this.previewDefaultSrc
        return this.sizeOutRange = true
      }
      this.sizeOutRange = false

      // valida o tipo correto
      var imageType = ["image/png", "image/jpeg", "image/jpg"].find(type => type === file.type)
      if (!imageType) {
        inputBanner.value = ''
        preview.src = this.previewDefaultSrc
        return this.typeOutRange = true
      }
      this.typeOutRange = false
      // setando file
      this.file = file
      preview.src = await this.getUrlFromFile(this.file)
    },

    /**
     * Metodo que transforma arquivo em url base64
     * @param file arquivo para conversao
     */
    getUrlFromFile: function (file) {
      var reader = new FileReader()
      if (file) {
        reader.readAsDataURL(file)
      }
      var url = new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
      return url
    },

    /**
     * Metodo para fazer upload da imagem
     * @param {File} file imagem a ser carregada
     */
    uploadImage: async function (file, oldUrl) {
      var formData = new FormData()
      formData.append('file', file)
      if (oldUrl && oldUrl != undefined) {
        var keyParts = oldUrl.split('/')
        var key = keyParts[keyParts.length - 1]
        if (key.split(".").length!=2)
          throw new Error
        formData.append('oldKey', key)
      }
      if (file) {
        var name = file.name
        if (name.split(".").length!=2)
          throw new Error
      }
      return await this.$tallos_academy_api.post('courses/upload', formData)
          .then(response => response.data)
          .catch(error => {
            this.$toasted.global.defaultError({msg: "Erro ao fazer upload. Tente novamente."})
          })
    },

    /**
     * Metodo para inserir tag
     */
    insertTag: function () {
      var parts = this.tag.split(',')
      parts.forEach(part => {
        part = part.replaceAll(/^((\s+)|(\s+))|(\s{2,})/g, ' ')
        part = part.replaceAll(/^(\s+)|(\s+)$/ig, '')
        if (part !== '') {
          this.course.tags.push(part.toLowerCase())
        }
      })
      this.tag = ''
    },

    /**
     * Metodo para deletar tag
     */
    deleteTag: function (event) {
      var newArray = this.course.tags.filter(tag => tag !== event.tag)
      this.course.tags = newArray
    },

    /**
     * Metodo que valida dados antes de enviar
     */
    beforeSaveValidation: function () {
      // titulo
      this.titleOk = this.course.title.length < 4 ? true : false
      // descrição
      this.descriptionOk = this.course.description.length < 10 ? true : false
      // tags
      this.tagsOk = this.course.tags.length === 0 ? true : false

      return (this.titleOk || this.descriptionOk || this.tagsOk)
    },

    /**
     * Metodo para atualizar o curso
     */
    updateCourse: async function () {
      this.spinner = true;
      if (this.beforeSaveValidation()) return
      if (this.file) {
        try {
          var {key} = await this.uploadImage(this.file, this.course.img);
          this.course.img = key
        } catch (err) {
          this.$toasted.global.defaultError({msg: "Erro ao fazer upload devido ao nome do arquivo. Tente novamente. "})
        }
      }
      this.course.updatedBy = this.$store.getters.admin.name;
      await this.$tallos_academy_api.put(`courses/${this.$route.params.id}`, this.course)
          .then(response => {
            this.$toasted.global.defaultSuccess({msg: `O curso '${this.course.title}' foi atualizado com sucesso.`})
            this.$router.go(-1)
          })
          .catch(error => {
            error.response.data.message.forEach(err => {
              this.$toasted.global.defaultError({msg: err})
            })
          })
      this.spinner = false;
    },

    /**
     * Metodo que busca o curso na inicialização
     */
    getCourse: async function () {
      await this.$tallos_academy_api.get(`courses/${this.$route.params.id}`)
          .then(response => {
            this.course = response.data;
          })
          .catch(() => {
            this.$toasted.global.defaultError({msg: 'Erro na busca dos cursos.'})
            this.$router.go(-1)
          })
    },

    /**
     * Metodo que seta algumas variaveis na inicialização
     */
    setDefault: function () {
      this.previewDefaultSrc = document.querySelector('#preview-banner').src
      this.course.title = ''
      this.course.description = ''
    }
  },

  /**
   * Lifecycle: Mounted
   */
  mounted() {
    this.getCourse()
    this.setDefault();
  }
}
</script>

<style scoped>

.custom-span-buttom {
  cursor: pointer;
  width: 180px;
  padding: .2rem .9rem;
}

.custom-image {
  object-fit: cover;
  width: 1280px !important;
  max-height: 360px !important;
}

</style>